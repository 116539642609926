$(document).foundation();

setTimeout(function(){	
	$('.later').fadeIn(2000).removeClass('present');
 }, 50);
 

$(document).scroll(function () {
    var y = $(this).scrollTop();
    if (y > 110) {
        $('.bannerbrand').fadeIn();
    } else {
        $('.bannerbrand').fadeOut();
    }
});


//COLORBOX MODALS
$(".inline-modal").colorbox({inline:true});
$(".ajax").colorbox();
$(".iframe").colorbox({iframe:true, width:"99%", height:"99%"});

//HANDLE EXTERNAL LINKS
$(document).ready(function() {

   $("a[href^=http]").each(function(){

      // NEW - excluded domains list
      var excludes = [
//       'excludeddomain.com',
         'orso.ie'
      ];
      for(i=0; i<excludes.length; i++) {
         if(this.href.indexOf(excludes[i]) !== -1) {
            return true; // continue each() with next link
         }
      }

      if(this.href.indexOf(location.hostname) === -1) {

           // attach a do-nothing event handler to ensure we can 'trigger' a click on this link
           $(this).click(function() { return true; }); 

           $(this).attr({
               target: "_blank",
               title: "Opens in a new window"
           });

           $(this).click(); // trigger it
      }
   });
});